article{
    padding-top: 150px;
    background: #F0F9FB;
    padding-bottom: 150px;
}
 .article__content-container {
    padding-left: 100px;
    padding-right: 100px;
}
article .article__content{
    padding: 50px 77px;
    border: 3px solid #0064a318;
    background: rgba(255, 255, 255, 0.1) ;
    box-shadow: 0px 0px 20px rgba(0, 101, 163, 0.5);
      
}
article .article__content h3{
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.05em;
    color: #111111;
    text-align: center;
}
article .article__content p{
    background: linear-gradient(to right, #FF002C 20%, #00956A 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    opacity: 0.8;
    margin-top: 30px;
    margin-bottom: 50px;
}
article .article__content button{
    margin-left: 45%;
    padding: 8px 28px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #00A1E4;
    background-color: transparent;
    outline: none;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 2px 20px rgba(0, 101, 163, 0.2);
}
article .article__content > button a{
    padding: 0;
}
article .article__content > button:hover{
    background-color: #00A1E4;
    color: white;
}
article .article__content > button:hover a{
    color: white;
}

@media only screen and (max-width: 992px){
    .article__content-container {
        padding-left: 80px;
        padding-right: 80px;
    }
    article {
        padding-top: 100px;
        background: #F0F9FB;
        padding-bottom: 100px;
    }
    article .article__content p {
        font-size: 17px;
    }
    article .article__content h3 {
        font-weight: 400;
        font-size: 25px;
    }
    article .article__content button {
        margin-left: 40%
    }
    article .article__content {
        padding: 45px 55px;
    }
}

@media only screen and (max-width: 768px){
    article .article__content p {
        font-size: 13px;
    }
    article .article__content h3 {
        font-weight: 400;
        font-size: 19px;
    }
    article .article__content button {
        margin-left: 38%;
        padding: 6px 24px;
    }
}
@media only screen and (max-width: 700px){
    article .article__content p {
        font-size: 11px;
        margin-top: 15px;
        margin-bottom: 35px;
    }
    article .article__content h3 {
        font-size: 16px;
    }
    article .article__content button {
        margin-left: 38%;
        padding: 4px 18px;
        font-size: 14px;
    }
}
@media only screen and (max-width: 578px){
    .article__content-container {
        padding-left: 70px;
        padding-right: 70px;
    }
    article{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    article .article__content {
        padding: 30px 25px;
    }
    article .article__content button {
        padding: 2px 15px;
        font-size: 12px;
    }
}
@media only screen and (max-width: 477px){
    .article__content-container {
        padding-left: 50px;
        padding-right: 50px;
    }
    article{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    article .article__content {
        padding: 16px 12px;
    }
    article .article__content p {
        font-size: 9px;
    }
    article .article__content h3 {
        font-size: 13px;
    }
    article .article__content button {
        padding: 2px 13px;
        font-size: 10px;
    }
}
@media only screen and (max-width: 440px){
    .article__content-container {
        padding-left: 15px;
        padding-right: 16px;
    }
    article .article__content p {
        margin-bottom: 15px;
    }
}