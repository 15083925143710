#connect{
    background: #F0F9FB;
    padding-bottom: 120px;
}
.connect__content-container{
    padding-right: 100px;
    padding-left: 100px;
}
#connect > h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 400px;
    margin-bottom: 65px;
    
}
#connect .form__container{
    padding: 50px;
    background: url(Connect/CCS\ 8.png) no-repeat;
    border: 3px solid #0064a318;
    box-shadow: 0px 0px 20px rgba(0, 101, 163, 0.5);
}
#connect .form__container .form__text-content h1{
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #00A1E4;
    margin: 130px 0;
}
#connect .form__container .form__text-content p{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #E31D26;
}
#connect .form__container .form__text-content p span:nth-child(1){
    color: #00A1E4;
}
#connect .form__container .form__text-content p span:nth-child(2){
    color: #009847;
}
#connect .form__container .form__content{
    padding: 50px 30px 80px 30px;
    border: 3px solid #0064a318;
    box-shadow: 0px 0px 20px rgba(0, 101, 163, 0.5);
}
#connect .form__container .form__content h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #00A1E4;
    margin-bottom: 2.3rem;
}
#connect .form__container .form__content input{
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    padding-top: 0;
    outline: none;
}
#connect .form__container .form__content input:focus{
    box-shadow: none;
}
#connect .form__container .form__content fieldset{
    border: 2px groove #00A1E4;
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding: 0 0.75rem 0.625rem 0.75rem;
}
#connect .form__container .form__content fieldset legend{
    font-size: 14px;
    line-height: 17px;
    color: #00A1E4;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: flex;
    width: 40px;
    margin-bottom: 0;
}
#connect .form__container .form__content fieldset:nth-child(2) legend{
    font-size: 14px;
    line-height: 17px;
    color: #00A1E4;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: flex;
    width: 110px;
    margin-bottom: 0;
    
}
#connect .form__container .form__content fieldset:nth-child(3) legend{
    font-size: 14px;
    line-height: 17px;
    color: #00A1E4;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: flex;
    width: 90px;
    margin-bottom: 0;
}
#connect .form__container .form__content fieldset:nth-child(3) textarea{
    background-color: transparent;
    border: none;
    outline: none;
}
#connect .form__container .form__content form #connect__button{
    border: 2px groove #00A1E4!important;
}


@media only screen and (max-width: 1200px){
    .connect__content-container{
        padding-right: 50px;
        padding-left: 50px;
    }
    #connect .form__container {
        padding: 30px;
    }
    #connect .form__container .form__text-content h1 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 1100px){
    #connect .form__container .form__content {
        padding: 40px 20px 60px 20px;
    }
    #connect .form__container .form__text-content h1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px){
    #connect .form__container {
        padding: 50px;
        background: 0;
        border: 0; 
        box-shadow: 0px 0px 0px 0px;
    }
    #connect .form__container .form__text-content h1 {
      margin: 60px 0;  
    }
    #connect > h2 {
        margin-bottom: 0;
    }
    #connect .form__container .form__text-content{
        margin-bottom: 100px;
    }
    #connect .form__container .form__content {
        padding: 40px 100px 60px 100px;
    }
}
@media only screen and (max-width: 768px){
    #connect > h2 {
      font-size: 28px;
    }
    #connect .form__container {
        padding: 30px;
    }
    #connect .form__container .form__text-content h1 {
        margin: 30px 0;
        font-size: 20px;
    }
    #connect .form__container .form__text-content p {
        font-size: 20px;
    }
    #connect .form__container .form__content {
        padding: 40px 50px 60px 50px;
    }
}
@media only screen and (max-width: 578px){
    #connect > h2 {
        font-size: 24px;
    }
    #connect .form__container .form__text-content h1 {
        font-size: 16px;
    }
    #connect .form__container .form__text-content {
        margin-bottom: 65px;
    }
    .connect__content-container {
        padding-right: 30px;
        padding-left: 30px;
    }
}
@media only screen and (max-width: 500px){
    #connect .form__container {
        padding: 10px;
    }
    #connect .form__container .form__content {
        padding: 40px 20px 60px 20px;
    }
    #connect > h2 {
        font-size: 16px;
    }
    #connect .form__container .form__text-content h1 {
        margin: 10px 0;
        font-size: 13px;
    }
    #connect .form__container .form__text-content p {
        font-size: 16px;
    }
    #connect .form__container .form__text-content {
        margin-bottom: 40px;
    }
}
@media only screen and (max-width: 430px){
    #connect .form__container {
        padding: 0px;
    }
    #connect {
        padding-bottom: 80px;
    }
    #connect > h2 {
        width: 210px;
    }
}
@media only screen and (max-width: 380px){
    #connect .form__container .form__content h4 {
        font-size: 18px;
        margin-bottom: 1.3rem;
    }
    #connect .form__container .form__content {
        padding: 30px 15px 40px 15px;
    }
    #connect > h2 {
       margin-bottom: 20px;
    }
}