footer{
    padding-top: 34px;
    padding-bottom: 20px;
    background: #E4F5FA; 
}
/* footer #navbar */
#navbar_footer nav{
    padding: 10px 75px 34px 84px;
}
#navbar_footer .nav-item a{
    margin-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #111111;
}
#navbar_footer #dropdown{
    margin-top: 9px;
}
#navbar_footer ul{
    margin-left: 70px;
}
#navbar_footer #dropdown i{
    width: 5px;
    height: 5px;
    color: #111111;
    margin-left: 5px;
    cursor: pointer;
}
#navbar_footer #dropdown a{
    text-decoration: none;
}
#navbar_footer #dropdown .dropdown .dropdown-menu{
    padding: 20px;
    margin-left: 60px;
    margin-top: 30px;
    height: 200px;
    overflow-y: scroll;
}
#navbar_footer #dropdown .dropdown .dropdown-menu a{
    font-size: 16px;
    color: #111111;
    line-height: 1rem;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    margin-left: 0;
    font-weight: normal;
}
#navbar_footer .footer__logos i{
    width: 5px;
    height: 5px;
    color: #111111;
    margin-right: 25px;
    cursor: pointer;
}
/* /footer #navbar */
footer .footer__info{
    padding: 15px 125px 70px 90px;

}
footer .footer__info .item__texts p{
    margin-bottom: 0;
}
footer .footer__info .row .footer__info-item .text-content{
  display: flex;
  justify-content: space-around;
}

footer .footer__info .text-content .first_item i{
    font-size: 20px;
    color: black;
    margin-top: 9px;
}
footer .footer__info .text-content .first_item p{
    line-height: 150%;
    letter-spacing: 0.05em;
}
footer .footer__info .text-content .first_item .item__texts{
    margin-left: 12px;
}
footer .footer__info .text-content .first_item .item__texts p a{
    color: black;
    text-decoration: none;
}
footer .footer__info .text-content_2 .first_item .item__texts p a{
    color: black;
    text-decoration: none;
}
footer .footer__info .text-content .second_item i{
    font-size: 20px;
    margin-top: 9px;
}
footer .footer__info .text-content .second_item{
    line-height: 150%;
    letter-spacing: 0.05em;
}
footer .footer__info .text-content_2 .second_item .items > a{
    color: black;
    text-decoration: none;
}
footer .footer__info .text-content .second_item .item__texts{
    margin-left: 12px;
}
footer .footer__info .text-content_2{
    margin-left: 25px;
    display: flex;
    justify-content: space-around;
}
footer .footer__info .text-content_2 .first_item i{
    font-size: 20px;
    margin-top: 9px;
}
footer .footer__info .text-content_2 .first_item{
    line-height: 150%;
    letter-spacing: 0.05em;
}
footer .footer__info .text-content_2 .first_item .item__texts{
    margin-left: 12px;
}
footer .footer__info .text-content_2 .first_item .item__texts p{
    margin-top: 6px;
}


footer .footer__info .text-content_2 .second_item i{
    font-size: 20px;
    margin-top: 9px;
}
footer .footer__info .text-content_2 .second_item{
    line-height: 150%;
    letter-spacing: 0.05em;
}
footer .footer__info .text-content_2 .second_item .item__texts{
    margin-left: 12px;
}
footer .footer__middle{
    border-bottom: 2px solid #111111 ;
    padding: 5px 380px;
}

footer .footer__bottom{
    padding-top: 20px;
}
footer .footer__bottom p{
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #111111;
    text-align: center;
}

@media only screen and (max-width: 1300px){
    #navbar_footer ul {
        margin-left: 130px;
    }
    footer .footer__middle{
        padding: 5px 320px;
    }
}
@media only screen and (max-width: 1160px){
    footer .footer__info {
        padding: 35px 110px 70px 150px;
    }
    #navbar_footer ul {
        margin-left: 70px;
    }
    footer .footer__info .text-content_2{
        margin-left: 25px;
    }
    
}
@media only screen and (max-width: 1140px){
    footer .footer__middle{
        padding: 5px 230px;
    }
}
@media only screen and (max-width: 1070px){
    #navbar_footer ul {
        margin-left: 10px;
    }
    footer .footer__info {
        padding: 115px 80px 70px 100px;
    }
    #navbar_footer nav {
        padding: 10px 60px 34px 80px;
    }
}

@media only screen and (max-width: 992px){
    footer .collapse{
        display: none;
    }
    footer #navbar_footer nav button{
        display: none;
    }
    #navbar_footer .footer__logos {
        margin-right: 160px;
    }
    #navbar_footer .footer__logos i{
        font-size: 15px;
    }
    footer .footer__info {
        padding: 115px 70px 70px 80px;
    }
    footer .footer__info .row .footer__info-item:nth-child(2) {
        justify-content: flex-start;
    }
    footer .footer__info .row .footer__info-item:nth-child(3) {
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 768px){
    footer .footer__info .row .footer__info-item:nth-child(2) {
       display: flex;
    }
    footer .footer__info .row .footer__info-item:nth-child(3) {
        display: flex;
    }
    footer .footer__info {
        padding: 90px 60px 60px 70px;
    }
    footer .footer__info p{
        font-size: 12px;
    }
    footer .footer__info .text-content_2 .second_item{   
        margin-top: 12px;
    }
    footer .footer__info .text-content_2 .second_item i{
        font-size: 20px;
    }   
}

@media only screen and (max-width: 650px){
    #navbar_footer .footer__logos {
        margin-right: 70px;
    }
    footer .footer__bottom p {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 578px){
    #navbar_footer nav {
        padding: 10px 10px 34px 40px;
    }
    footer .footer__info .row .footer__info-item:nth-child(2) {
        max-width: 50%;
     }
     #navbar_footer .footer__logos i {
        font-size: 25px;
    }
    #navbar_footer .footer__logos a {
        margin-right: 20px;
    }
    #navbar_footer .footer__logos {
        margin-right: 7px;
    }
    footer .footer__info .row .footer__info-item:nth-child(3) {
      width: 60%;
    }
    footer .footer__info .text-content_2 {
        margin-left: 80px;
    }
    footer .footer__info {
        padding: 60px 30px 50px 40px;
    }
    footer .footer__info .row .footer__info-item .text-content{
        flex-direction: column;
    }
    footer .footer__info .row .footer__info-item .text-content_2{
        flex-direction: column;
        margin-left: -10px;
    }
    footer .footer__info > .row>* {
        max-width: 50%;
    }
    footer .footer__info .second_item {     
        margin-top: 20px;
    }
    footer .footer__middle{
        padding: 5px 30px;
        font-size: 12px;
    }
}
@media only screen and (max-width: 517px){
    #navbar_footer nav {
        padding: 10px 30px 34px 29px;
    }
    footer .footer__bottom p {
        font-size: 0.875rem;
    }
    footer .footer__info p {
        font-size: 11px;
        font-weight: bold;
    }
    footer .footer__info i{
        font-size: 16px;
    }
    footer .footer__info .text-content_2 {
        margin-left: 18px;
    }
    footer .footer__middle p{
        font-weight: 700;
    }
}
@media only screen and (max-width: 460px){
    #navbar_footer nav {
        padding: 10px 20px 34px 20px;
    }
    footer .footer__info .text-content_2 .second_item i {
        margin-top: 20px;
    }
    footer .footer__info .row .footer__info-item .text-content_2{
        margin-left: 20px;
    }
}
@media only screen and (max-width: 424px){
    footer .footer__info .text-content_2 .first_item .item__texts p {
        margin-top: 7px;
    }
    footer .footer__info p {
        font-size: 10px;
    }    
    footer .footer__middle p{
        font-size: 10px;
    }
}

@media only screen and (max-width: 413px){
    footer .footer__info {
        padding: 60px 15px 50px 15px;
    }
    #navbar_footer .footer__logos a {
        margin-right: 15px;
    }
    #navbar_footer .footer__logos {
        margin-right: 0px;
    }
    footer .footer__bottom p {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 382px){
    #navbar_footer nav {
        padding: 10px 10px 34px 5px;
    }
    #navbar_footer .logo img{
        width: 80px;
        height: 30px;
    }
}

@media only screen and (max-width: 364px){
    footer .footer__info p {
        font-size: 9px;
    }    
    footer .footer__info .text-content_2 .second_item i {
        margin-top: 14px;
    }
    footer .footer__info .row .footer__info-item .text-content_2 {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 360px){
    footer .footer__middle {
        padding: 5px 20px;
    }
    footer .footer__middle p{
     font-size: 9px;
    }
}
