#search{
    background: #F0F9FB;
    padding-top: 70px;
}
#search button:hover a{
    color: white;
}
#search .catalog__item{
    background: #F0F9FB;
    margin-bottom: 50px;
    width: 393px;
}
#search .catalog__item .item__photo {
    width: 100%;
    margin-top: 0;
}
#search .catalog__item .item__photo img{
    width: 100%;
}
#search .search-categories{
    padding-right: 100px;
    padding-left: 100px;
}

#search .search-categories > h4{
    margin-bottom: 40px;
}

@media only screen and (max-width: 900px){
    .search-categories {
        padding-right: 70px;
        padding-left: 70px;
    }
}

@media only screen and (max-width: 800px){
    .search-categories {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (max-width: 767px){
    .search-categories {
        padding-right: 0;
        padding-left: 0;
    }
    .search-categories > h4{
        padding: 0 20px;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 500px){
    .search-categories > h4{
        padding: 0 20px;
        font-size: 1rem;
    }
    #search .search-categories {
        padding-left: 20px;
    }
}
@media only screen and (max-width: 420px){
    #search .catalog__item{
        width: 300px;
    }
}
@media only screen and (max-width: 400px){
    .search-categories > h4{
        padding: 0 20px;
        font-size: 0.8rem;
    }
}

