#developers{
    position: relative;   
    color: white;
    font-family: 'Oswald';
}

#developers img{
    width: 100%;
    height: 100%;
}

#developers h1{
    position: absolute;
    font-size: 48px;
    text-transform: uppercase;
}

#developers h1:nth-child(2){
    bottom: 55%;
    left: 5%;
}
#developers h1:nth-child(3){
    position: absolute;
    bottom: 50%;
    left: 5%;
}

#developers h2{
    position: absolute;
    font-weight: bold;
    bottom: 45%;
    left: 5%;
    font-size: 48px;
    text-transform: uppercase;
}
#developers p{
    position: absolute;
    bottom: 35%;
    left: 5%;
    font-size: 30px;
}
#developers p a{
    color: white;
}

@media only screen and (max-width: 1580px){
    #developers h1{
        font-size: 40px;
    }   
    #developers h2{
        font-size: 40px;
    }
    #developers p{
        font-size: 25px;
    }
}

@media only screen and (max-width: 1300px){
    #developers h1{
        font-size: 35px;
    }   
    #developers h2{
        font-size: 35px;
    }
    #developers p{
        font-size: 20px;
    }
}

@media only screen and (max-width: 1120px){
    #developers h1{
        font-size: 30px;
    }   
    #developers h2{
        font-size: 30px;
    }
    #developers p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 1000px){
    #developers{
        height: 100vh;
        width: 100vw;
    }
    #developers h1:nth-child(2){
        bottom: 35%;
    }
    #developers h1:nth-child(3){
        bottom: 28%;
    }
    #developers h2{
        bottom: 21%;
    }
    #developers p{
        bottom: 12%;
    }
}
 @media only screen and (max-width: 576px){
    #developers h1{
        font-size: 25px;
    }   
    #developers h2{
        font-size: 25px;
    }
    #developers p{
        font-size: 18px;
    }    
}
@media only screen and (max-width: 470px){
    #developers h1{
        font-size: 20px;
    }   
    #developers h2{
        font-size: 20px;
    }
    #developers p{
        font-size: 15px;
    }    
}
@media only screen and (max-width: 360px){
    #developers h1{
        font-size: 16px;
    }   
    #developers h2{
        font-size: 16px;
    }
    #developers p{
        font-size: 12px;
    }    
}
/*@media only screen and (max-width: 615px){
    #developers h1{
        font-size: 20px;
    }   
    #developers h2{
        font-size: 20px;
    }
    #developers p{
        font-size: 15px;
    }    
}

@media only screen and (max-width: 480px){
    #developers h1{
        font-size: 16px;
    }   
    #developers h2{
        font-size: 16px;
    }
    #developers p{
        font-size: 12px;
    }
}

@media only screen and (max-width: 480px){
    #developers h1{
        font-size: 14px;
    }   
    #developers h2{
        font-size: 14px;
    }
    #developers p{
        font-size: 10px;
    }
} */
@media only screen and (max-width: 345px){
    #developers h1:nth-child(2){
        bottom: 30%;
    }
    #developers h1:nth-child(3){
        bottom: 25%;
    }
    #developers h2{
        bottom: 20%;
    }
    #developers p{
        bottom: 10%;
    }
}