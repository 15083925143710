#location{
    background: #F0F9FB;
    padding-bottom: 120px;
}
#location > h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 400px;
    margin-bottom: 70px;
    margin-left: 100px;
}
#location .location__map iframe{
    width: 100%;
    height: 100vh;
}
@media only screen and (max-width: 576px){
    #location > h2 {
        font-size: 22px;
        width: 300px;
        margin-bottom: 40px;
        margin-left: 40px;
    }
    #location {
        padding-bottom: 70px;
    }
}
@media only screen and (max-width: 400px){
    #location > h2 {
        font-size: 16px;
        width: 230px;
        margin-bottom: 20px;
        margin-left: 30px;
    }
}