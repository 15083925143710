.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    margin-bottom: 0;
  }
  p{
    margin-bottom: 0;
  }

header{
    padding-top: 23px;
    padding-bottom: 20px;
    display: flex;
    background: #E4F5FA; 
}
.header__content-container{
    padding-left: 100px;
    padding-right: 100px;
}
/* Header .text-content */
header .logo a img{
    width: 160px;
    height: 60px;
}
header .text-content{
    margin-left: 480px;
}
header .text-content .first_item{
    color: #E31D26;    
}
header .text-content .first_item i{
    font-size: 20px;
    margin-top: 9px;
}
header .text-content .first_item{
    font-size: 13px;    
    line-height: 15px;
    letter-spacing: -0.02em;
}
header .text-content .first_item .items a{
    color: #E31D26;
}
header .item__texts p{
    margin-bottom: 0!important;
}
header .text-content .first_item .item__texts{
    margin-left: 12px;
}
header .text-content .first_item .item__texts a{
    color: #E31D26;    
    text-decoration: none;
}
header .text-content .second_item{
    color: #009847;    
    margin-top: 12px;
}
header .text-content .second_item i{
    font-size: 20px;
    margin-top: 9px;
}
header .text-content .second_item{
    font-size: 13px;    
    line-height: 15px;
    letter-spacing: -0.02em;
}
header .text-content .second_item .item__texts{
    margin-left: 12px;
}
/* /Header .text-content */

/* Header .text-content_2 */
header .text-content_2{
    margin-left: 90px;
}
header .text-content_2 .first_item{
    color: #009847;    
}
header .text-content_2 .first_item i{
    font-size: 20px;
    margin-top: 9px;
}
header .text-content_2 .first_item{
    font-size: 13px;    
    line-height: 15px;
    letter-spacing: -0.02em;
}
header .text-content_2 .first_item .item__texts{
    margin-left: 12px;
}
header .text-content_2 .first_item .item__texts p{
    margin-top: 10px;
}
header .text-content_2 .first_item .item__texts p a{
    color: #009847;
    text-decoration: none;
}
header .text-content_2 .second_item{
    color: #00A1E4;    
    margin-top: 12px;
}
header .text-content_2 .second_item i{
    font-size: 20px;
    margin-top: 9px;
}
header .text-content_2 .second_item{
    font-size: 13px;    
    line-height: 15px;
    letter-spacing: -0.02em;
}
header .text-content_2 .second_item .item__texts{
    margin-left: 12px;
}
/* /Header .text-content_2 */

/* Header .text-content_3 */
header .text-content_3{
    margin-left: 20px;
}
header .text-content_3 .first_item{
    color: #009847;    
}
header .text-content_3 .first_item button{
    padding: 8px 26px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    box-shadow: 0 0 3px 0 grey;
    outline: none;
    border: none;
}
header .text-content_3 .first_item button a{
    color: #dc3545;
    text-decoration: none;
}
header .text-content_3 .first_item:hover button a{
    color: white;
}
header .text-content_3 .second_item{
    margin-left: 55px;
}
header .text-content_3 .second_item{
    color: #00A1E4;    
    margin-top: 12px;
}
header .text-content_3 .second_item h6{
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
}
header .text-content_3 .second_item h6 a{
    color: #00A1E4;    
    text-decoration: none;
}
header .text-content_3 .second_item h6 a:hover{
    text-decoration: underline;
}
/* /Header .text-content_3 */
/* /Header */

@media only screen and (max-width: 1480px){
header .text-content {
    margin-left: 400px;
}
}

@media only screen and (max-width: 1405px){
    header .text-content {
        margin-left: 320px;
    }
}

@media only screen and (max-width: 1321px){
    header .text-content {
        margin-left: 330px;
    }
}
@media only screen and (max-width: 1298px){
    .header__content-container{
        padding-right: 100px;
    }
    header .text-content_3 .first_item button {
        padding: 5px 10px;
        font-size: 13px;
    }
    header .text-content_3 .second_item {
        margin-left: 1px;
    }
    header .text-content_3 .second_item h6 {
        font-size: 14px;
    }
    header .text-content {
        margin-left: 330px;
    }
}
@media only screen and (max-width: 1234px){
    header .text-content {
        margin-left: 240px;
    }
}
@media only screen and (max-width: 1170px){
    header .text-content {
        margin-left: 200px;
    }
}
@media only screen and (max-width: 1111px){
    header .text-content {
        margin-left: 130px;
    }
}
@media only screen and (max-width: 1037px){
    .header__content-container {
        padding-left: 40px;
    }
}
@media only screen and (max-width: 992px){
    header .logo{
        margin-top: 10px;
    }
    header .logo a img {
        width: 120px;
        height: 50px;
    }
    header .text-content {
        margin-left: 110px;   
    }
    header .text-content .item__texts {
        font-size: 11px;
    }
    header .text-content .first_item i {
        font-size: 15px;
    }
    header .text-content .second_item i {
        font-size: 15px;
    }
    header .text-content_2 .first_item i {
        font-size: 15px;
    }
    header .text-content_2 .second_item i {
        font-size: 15px;
    }
}

@media only screen and (max-width: 917px){
    header .text-content .item__texts {
        font-size: 10px;
    }

    header .text-content_3 .first_item button {
        padding: 5px 10px;
        font-size: 12px;
    }
    header .text-content_3 .second_item h6 {
        font-size: 13px;
    }
}

@media only screen and (max-width: 874px){
    header .text-content {
        margin-left: 100px;
    }
}


@media only screen and (max-width: 862px){
    header .text-content_2 {
        margin-left: 37px;
    }
    .nav-link {
        display: block;
        padding: 0.5rem 0.5rem;
    }
}

@media only screen and (max-width: 821px){
    header .text-content {
        margin-left: 40px;
    }
    header .text-content_3 .first_item button {
        padding: 5px 10px;
        font-size: 12px;
    }
}


@media only screen and (max-width: 768px){
   header .text-content{
       display: none;

   }
   header .text-content_2{
        display: none;    
    }
    header .text-content_3 {
        margin-left: 200px;
    }
    header .logo {
        margin-top: 5px;
    }
    .header__content-container {
        padding-right: 50px;
        display: flex;
        justify-content: space-between;
    }
}


@media only screen and (max-width: 578px){
    header .text-content_3 .second_item h6 {
       display: none;
    }
    header .text-content_3 {
        margin-left: 110px;
        margin-top: 15px;
    }
}
@media only screen and (max-width: 488px){
    .header__content-container{
            padding-right: 0px;
    }
    .header__content-container {
        padding-right: 30px;
    }
}
@media only screen and (max-width: 400px){
    header {
        padding-top: 12px;
        padding-bottom: 15px;
    }
    header .text-content_3 {
        margin-left: 35px;
    }
    header .logo {
        margin-top: 13px;
    }
    .header__content-container {
        padding-left: 70px;
    }
    header .logo a img {
        width: 80px;
        height: 30px;
    }
    header .text-content_3 .first_item button {
        padding: 4px 8px;
        font-size: 10px;
    }
    
}