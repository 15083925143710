#categoriesList{
    padding: 100px 130px 150px 100px;
    background: #F0F9FB;
}
#categoriesList button:hover a{
    color: white;
}
#categoriesList > h2{ 
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    width: 670px;
    text-transform: uppercase;
    background: linear-gradient(45deg, red -8%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#categoriesList > h3{
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    color: #00A1E4;
    margin-top: 30px;
    margin-bottom: 20px;
}
#categoriesList > p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.05em;
    color: #009847;
    opacity: 0.8;
    margin-bottom: 50px;
}
#categoriesList > h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;    
    color: #E31D26;
}
#categoriesList > h5{
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    color: #00A1E4;
    margin-bottom: 30px;
}
#categoriesList .row{
    margin-bottom: 29px;
}
#categoriesList .row .categories__item img{
    width: 100%;
    height: 100%;
}
#categoriesList .row .categories__item h4{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #0E302C;
    margin-top: 30px;
    margin-bottom: 10px;
}
#categoriesList .row .categories__item p{
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #111111;
    opacity: 0.6;
    margin-bottom: 10px;
}
#categoriesList .row .categories__item:nth-child(3){
    display: flex;
    align-items: center;
}
#categoriesList .row .categories__item:nth-child(3) button{
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    padding: 6px 32px 6px 32px;
    background: transparent;
    color: #00A1E4;    
}
#categoriesList .row .categories__item:nth-child(3) button:hover{
    background: #00A1E4;
    color: #FFFFFF;
}

/* CategoriesItem */

#image__box{
    padding: 55px 0 100px 0;
}
#image__box > p{
    margin-top: 53px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #111111;
}
#image__box img{
    width: 100%;
    background-color: white;
}
#image__box .image__box-items{
    margin-top: 40px;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
#image__box .image__box-texts{
    margin-left: 100px;
    padding-top: 100px;
}
#image__box .image__box-texts button:nth-child(1){
    padding: 15px 35px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #00A1E4;
    background-color: white;
    border: none;
    outline: none;
    display: block;
}
#image__box .image__box-texts button:nth-child(1) a{
    text-decoration: none;
}
#image__box .image__box-texts button:nth-child(1) a:hover{
    text-decoration: underline;
}
#image__box .image__box-texts button:nth-child(1):hover a{
    color: #00A1E4;
}
#image__box .image__box-texts button:nth-child(1) img{
    width: 32px;
    height: 32px;
}
#image__box .image__box-texts button:nth-child(2){
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #00A1E4;
    margin-top: 30px;
    padding: 15px 33px;
    background-color: #fff;
    border: none;
    outline: none;
    display: block;
}
#image__box .image__box-texts .image__box-texts-content{
    margin-top: 52px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item p{
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #111111;
    margin-left: 25px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item a{
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #00A1E4;    
}

#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item img{
    background-color: transparent;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item:nth-child(2){
    margin-top: 30px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item:nth-child(3){
    margin-top: 30px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item:nth-child(1) img{
    width: 24px;
    height: 29px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item:nth-child(2) img{
    width: 24px;
    height: 24px;
}
#image__box .image__box-texts .image__box-texts-content .image__box-texts-content-item:nth-child(3) img{
    width: 24px;
    height: 24px;
}

/* /CategoriesItem */

@media only screen and (max-width: 1230px){
    #categoriesList{
        padding: 100px 70px 150px 100px;
        background: #F0F9FB;
    }
}


@media only screen and (max-width: 785px){
    #categoriesList {
        padding: 100px 80px 150px 100px;
    }    
    #categoriesList > h2 {
        font-size: 24px;
        width: 505px;
    }
}
@media only screen and (max-width: 622px){
    #categoriesList > h2 {
        font-size: 16px;
        width: 350px;
    }
}
@media only screen and (max-width: 576px){
    #categoriesList {
        padding: 100px 0px 100px 0px;
    }
    #categoriesList > h2{
        font-size: 14px;
        padding-left: 20px;
    }
    #categoriesList > h3{
        font-size: 12px;
        margin-top: 10px;
        margin-left: 20px;
    }
    #categoriesList > h4 {
        font-size: 12px;
        margin-left: 20px;
    }
    #categoriesList > p{
        font-size: 8px;
    }
    #image__box .image__box-texts {
        margin-left: 0px;
        padding-top: 100px;
    }
    #image__box .image__box-content{
        padding: 0 25px;
    }
    #image__box .image__box-items .image__box-item{
        max-width: 31%;
        margin-right: 4%;
    }
    #categoriesList {
        padding: 100px 0px 150px 0px;
    }    
    #categoriesList > .row .categories__item:nth-child(3) button {
     width: 100%;   
    }
    #image__box .image__box-texts button:nth-child(1) {
        margin: 0 auto;
    }
    #image__box .image__box-texts button:nth-child(2) {
        width: 100%;
        font-size: 16px;
    }
    #image__box .image__box-texts .image__box-texts-content {
        margin-left: 23px;
    }
    #categoriesList .row .categories__item:nth-child(1){
        padding-left: 20px;
    }
    #categoriesList .row .categories__item:nth-child(2){
        padding-left: 20px;
    }
    #categoriesList .row .categories__item:nth-child(3) button{
        outline: none;
        border: none;
        box-shadow: 0 0 7px 0 black;
    }
    #image__box > p {
        font-size: 12px;
        padding: 0 20px 0 30px;
    }
    #categoriesList > h5 {
        font-size: 14px;
        padding-left: 20px;
    }
    #categoriesList .row .categories__item:nth-child(3) button {
        outline: none;
        border: none;
        box-shadow: 0 0px 1px 0px black;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 395px){
    #categoriesList > h2 {
        font-size: 12px;
         width: 275px;
    }
    #categoriesList > h4 {
        font-size: 10px;
    }
    #image__box > p {
        font-size: 10px;
    }
}