#projects{
    background: #F0F9FB;
    padding-bottom: 150px;
}
.projects__content-container{
    padding-left: 100px;
    padding-right: 100px;
}
#projects > h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 430px;
    margin-bottom: 70px;
}
#projects .row img{
    width: 100%;
}
#projects .row p{
    line-height: 20px;
    color: #111111;
    margin-top: 20px;
    margin-bottom: 70px;
}
#projects .row p a{
    color: #111111;
    text-decoration: none;
}
#projects .row p a:hover{
    text-decoration: underline;
}
@media only screen and (max-width: 576px){
    #projects {
        padding-bottom: 100px;
    }
    .projects__content-container {
        padding-left: 50px;
        padding-right: 50px;
    }
    #projects > h2 {
        font-size: 24px;
        margin-bottom: 45px;
    }
}

@media only screen and (max-width: 500px){
    #projects > h2{
        width: 400px;
    }
    #projects .row p {
        line-height: 20px;
        color: #111111;
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .projects__content-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (max-width: 420px){
    #projects > h2{
        width: 300px;
    }
}


@media only screen and (max-width: 330px){
    #projects > h2 {
        font-size: 24px;
        margin-bottom: 26px;
    }
    #projects > h2 {
        width: 240px;
    }
}
