#catalogs{
    background: #F0F9FB;
}
.catalogs__content-container{
    padding-right: 100px;
    padding-left: 100px;
}

#catalogs > h2{ 
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 500px;
    background: linear-gradient(45deg, red -8%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#catalogs > p{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    letter-spacing: 0.05em;
    color: #009847;
    opacity: 0.8;
    margin-top: 30px;
    margin-bottom: 70px;
  
}
#catalogs .row{
    margin-bottom: 50px;
    justify-content: space-around;
}

#catalogs > .row >*{
    margin-right: 0;
    margin-left: 0;
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
}
#catalogs .catalog__item {
    width: 393px;
}
#catalogs .catalog__item .item__photo{
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
    height: 262px;
    position: relative;
}
#catalogs .catalog__item .item__photo img{
    width: 100%;
    position: absolute;
    height: 100%;
}
#catalogs .catalog__item h3{
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #00A1E4;
}
#catalogs .catalog__item p{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #00A1E4;
}
#catalogs button{
    margin-left: 45%;
    padding: 8px 28px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #00A1E4;
    background-color: transparent;
    outline: none;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 2px 20px rgba(0, 101, 163, 0.2);
    margin-bottom: 100px;
}
#catalogs > button a{
    padding: 0;
}
#catalogs > button:hover{
    background-color: #00A1E4;
    color: white;
}
#catalogs > button:hover a{
    color: white;
}

/* Breakpoints */

@media only screen and (max-width: 1380px){
    .catalogs__content-container{
        padding-right: 70px;
        padding-left: 70px;
    }
}
@media only screen and (max-width: 1320px){
    .catalogs__content-container{
        padding-right: 50px;
        padding-left: 50px;
    }
}
@media only screen and (max-width: 1320px){
    .catalogs__content-container{
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media only screen and (max-width: 900px){
    .catalogs__content-container {
        padding-right: 70px;
        padding-left: 70px;
    }
    #catalogs > h2{ 
        font-size: 28px;
    }
    #catalogs > p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 800px){
    .catalogs__content-container {
        padding-right: 50px;
        padding-left: 50px;
    }
    #catalogs > h2{ 
        font-size: 28px;
    }
    #catalogs > p{
        font-size: 16px;
    }
    .catalog__item p {
        font-size: 12px;
      
    }
    .catalog__item h3 {
        font-size: 20px;
    }
    #catalogs > p{
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px){
    .catalogs__content-container {
        padding-right: 0;
        padding-left: 0;
    }
    .catalog__item p {
        font-size: 12px;
        padding-left: 50px; 
    }
    .catalog__item h3 {
        padding-left: 50px; 
        font-size: 20px;
    }
    .catalog__item .item__photo{
        height: 300px;
    }
    #catalogs > h2{ 
        padding-left: 50px;
    }
    #catalogs > p{
        padding-left: 50px;
    }
    .row {
        --bs-gutter-x: 0rem;
        --bs-gutter-y: 0;
    }
}
@media only screen and (max-width: 578px){
    #catalogs > h2{ 
        font-size: 20px;
        padding-left: 20px;
        width: 400px;
    }
    #catalogs > p{
        font-size: 14px;
        padding-left: 20px;
    }
    .catalog__item p {
        padding-left: 20px; 
    }
    .catalog__item h3 {
        padding-left: 20px; 
    }
    #catalogs button {
        margin-left: 35%;
    }
}

@media only screen and (max-width: 401px){
    #catalogs > p {
        margin-bottom: 20px;
    }
    #catalogs > h2{ 
        width: 250px;
    }
}

@media only screen and (max-width: 340px){
    #catalogs .catalog__item {
        width: 100%;
    }
    #catalogs .catalog__item .item__photo{
        width: 100%;
        margin-top: 50px;
        background-color: #fff;
        height: 230px;
        position: relative;
    }
}
