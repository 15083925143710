
#navbar{
    position: relative;
}
#navbar{
    padding: 10px 0 10px 170px;
    background-color: #109EE5;
}

#navbar nav .navbar-nav > a, #navbar nav .navbar-nav .dropdown > a{
    margin-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
#navbar nav .navbar-nav > .active:nth-child(1){
    text-decoration: none;
}
#navbar nav .navbar-nav > .active{
    text-decoration: underline;
}
#navbar #dropdown{
    margin-top: 9px;
}
#navbar #dropdown i{
    width: 5px;
    height: 5px;
    color: white;
    margin-left: 5px;
    cursor: pointer;
}
#navbar .dropdown .dropdown-menu{
    padding: 20px;
    margin-left: -10px;
    margin-top: 15px;
    background-color: #00A1E4;
    height: 200px;
    overflow-y: scroll;
}
#navbar .dropdown .dropdown-menu a{
    font-size: 16px;
    color: white;
    line-height: 1rem;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    margin-left: 0;
    font-weight: normal;
}
#navbar .dropdown .dropdown-menu a:hover{
    background-color: transparent;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 0;
}
.languages {
    background-color: #fff;
    padding: 9px 12px 7px 0px;
    margin-left: 75px;
}
.languages .row{
    margin-left: 10px;
}
.languages .row p{
    margin-bottom: 0;
}
.languages .circle_red{
    background-color: #E31D26;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
}
.languages .circle_blue{
    background-color:  #00A1E4;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
}
.languages .circle_green{
    background-color: #009847;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
}
.languages .row .text__red{
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: red;
}
.languages .row .text__red a{
    color: red;
}
.languages .row .text__blue{
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #00A1E4;
}
.languages .row .text__blue a{
    color: #00A1E4;
}
.languages .row .text__green{
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #009847;
}
.languages .row .text__green a{
    color: #009847;
}
.languages .row .active{
    font-weight: 500;
}

#navbar nav .navbar-nav .languages a{
    font-size: 100%;
    text-decoration: none;

}

#navbar form{
    margin-left: 34px;
    padding-top: 8px;
    position: absolute; 
    right: 4%;
}
#navbar form input{
    padding: 6px;
}


/* Breakpoints */

@media only screen and (max-width: 1405px){
    #navbar {
    padding: 11px 0 10px 110px;
    background-color: #109EE5;
    }
}
@media only screen and (max-width: 1321px){
    #navbar {
    padding: 11px 0 10px 80px;
    background-color: #109EE5;
    }
}
@media only screen and (max-width: 1298px){
    #navbar {
    padding: 11px 0 10px 70px;
    background-color: #109EE5;
    }
    #navbar nav .navbar-nav > a, #navbar nav .navbar-nav .dropdown > a{
        font-size: 14px;
    }
    .languages {
        padding: 10px 8px 5px 5px;
        margin-left: 60px;
    }
    .languages .circle_red {
        width: 14px;
        height: 14px;
    }
    .languages .circle_blue {
        width: 14px;
        height: 14px;
    }
    .languages .circle_green {
        width: 14px;
        height: 14px;
    }
    .languages .row .text__red {
        font-size: 12px;
    }
    .languages .row .text__blue {
        font-size: 12px;
    }
    .languages .row .text__green{
        font-size: 12px;
    }
}

@media only screen and (max-width: 1200px){
    #navbar {
        padding: 11px 0 10px 30px;
    }
}

@media only screen and (max-width: 1170px){
    .languages {
        padding: 10px 7px 5px 0px;
        margin-left: 13px;
    }      
}

@media only screen and (max-width: 1111px){
    #navbar form{
        right: 2%;
    }
}

@media only screen and (max-width: 1070px){
    #navbar nav .navbar-nav > a, #navbar nav .navbar-nav .dropdown > a {
        margin-left: 30px;
    }
}



@media only screen and (max-width: 992px){
    #navbar{
        background: #F0F9FB;
    }
    #navbar nav .navbar-collapse{
        background-color: #109EE5;
        position: absolute;
        z-index: 3;
        top: 55px;
    }
    #navbar nav .navbar-nav > a, #navbar nav .navbar-nav .dropdown > a{
        border: 1px solid rgba(0, 0, 0, 0.295);  
        margin-left: 0;
        padding-left: 10px;
    }
    #navbar .dropdown .dropdown-menu a {
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 0;
        padding-right: 0;
    }
    #navbar .dropdown .dropdown-menu {
        padding: 0px;
        margin-left: 0px;
        margin-top: 0px;
    }
    .languages{
        background: transparent;    
        border: 1px solid rgba(0, 0, 0, 0.295);  
        margin-left: 0;
       
    }
    .languages .circle_red {
        width: 12px;
        height: 12px;
    }
    .languages .row .text__red {
        font-size: 12px;
    }
    .languages .circle_blue {
        background-color:  white;
        width: 12px;
        height: 12px;
    }
    .languages .row .text__blue {
        font-size: 12px;
        color: white;
    }
    .languages .circle_green {
        width: 12px;
        height: 12px;
    }
    .languages .row .text__green {
        font-size: 12px;
    }
    #navbar form{
        right: 2%;
    }
    .languages .row .text__blue a {
        color: white;
    }
}


@media only screen and (max-width: 578px){
    #navbar nav .navbar-collapse {
        top: 65px;
        left: -32px;
        z-index: 13;
    }
    main .carousel-indicators {
        margin-bottom: 10px;
    }
    #navbar .dropdown .dropdown-menu a {
        margin-bottom: 0px;
        font-size: 12px;
    }
    #navbar nav .navbar-nav > a, #navbar nav .navbar-nav .dropdown > a {
        font-size: 12px;
    }
    .languages {
        border: 0px solid rgba(0, 0, 0, 0.295);
        margin: 0 auto;
    }
    .languages .circle_red {
        width: 10px;
        height: 10px;
    }
    .languages .row .text__red {
        font-size: 10px;
    }
    .languages .circle_blue {
        background-color:  white;
        width: 10px;
        height: 10px;
    }
    .languages .row .text__blue {
        font-size: 10px;
        color: white;
    }
    .languages .circle_green {
        width: 10px;
        height: 10px;
        color:  #009847;
    }
    .languages .row .text__green {
        font-size: 10px;
        color:  #009847;
    }
}
@media only screen and (max-width: 400px){
    #navbar .dropdown .dropdown-menu a {
        font-size: 10px;
    }
}
@media only screen and (max-width: 355px){
    #navbar {
        padding: 11px 0 10px 10px;
    }
    #navbar nav .navbar-collapse {
        top: 65px;
        left: -10px;
    }
}
@media only screen and (max-width: 340px){
    #navbar form > input {
        width: 150px;
    }
}
