@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

*{
    margin: 0;
    margin-bottom: 0px;
    padding: 0;
    box-sizing: border-box;
  /*   font-family: 'Montserrat' sans-serif; */
    font-style: normal;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    margin-bottom: 0;
}
p{
    margin-bottom: 0;
}

/* Main */
#about .about__main{
    /*background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
    url('./About/main.png');*/
    background-size: cover;
    height: 80%;
    width: 100%;
    position: relative;
    background-position: left;
}
#about .about__content-container{
    padding-right: 100px;
    padding-left: 100px;
}
#about .about__main img{
    width: 100%;
}
#about .about__main h1{
    position: absolute;
    bottom: 10%;
    left: 100px;
    font-weight: 700;
    font-style: normal;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}
/* /Main */

/* text-content */
#about .about__text-content{
    padding: 100px 124px 150px 100px;
    background: #F0F9FB;
}
#about .about__text-content p{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #111111;
    line-height: 32px;
    text-indent: 4%;
}
#about .about__text-content p:nth-child(1){
    text-indent: 0;
}
#about .about__text-content p span{
    line-height: 29.26px;
    font-size: 24px;
    font-family: 'Montserrat';
}
/* /text-content */

/* /image-content */
#about .about__image-content{
    background: #F0F9FB;
    box-sizing: border-box;
    padding-bottom: 150px;
}
#about .about__image-content .image-container{
    padding: 70px 0 100px 0;
    background: url('./About/background.png') no-repeat;
    background-position: right;
    border: 3px solid #0064a318;
    box-shadow: 0px 0px 20px rgba(0, 101, 163, 0.5);
    justify-content: space-around;
}
#about .about__image-content .image-container h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 400px;
    margin: 0 auto;
}
#about .about__image-content .image-container .row{
   margin-left: 0;
   margin-right: 0;
   padding-right: 39px;
}
#about .about__image-content .image-container .row .img-block{
    margin-top: 80px;
    margin-left: 3.3%;
    justify-content: space-evenly;    
    padding: 0!important;
}
#about .about__image-content .image-container .row .img-block img{
    width: 100%;
}
/* /image-content */

/* /history */
#about .about__history{
    background: #F0F9FB;
    padding-bottom: 140px;
}
#about .about__history h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 400px;
}
#about .about__history p{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #009847;
    opacity: 0.8;
    margin-top: 30px;
}
/* /history */

/* quality */
#about .about__quality{
    background: #F0F9FB;
    padding-bottom: 140px;
}
#about .about__quality h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 200px;
}
#about .about__quality p{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-top: 30px;
    color: #00A1E4;
}
/* /quality */

@media only screen and (max-width: 1240px){
    #about .about__main h1{
        font-size: 40px;
    }
}

@media only screen and (max-width: 1020px){
    #about .about__main h1{
        font-size: 30px;
    }
}

@media only screen and (max-width: 992px){
    #about .about__image-content .image-container{
        background: transparent no-repeat;
    }
}

@media only screen and (max-width: 768px){
    #about .about__main h1{
        font-size: 24px;
    }
    #about .about__text-content p {
        font-size: 16px;
    }
    #about .about__image-content .image-container .row {
        padding-right: 0px;
    }
    #about .about__text-content {
        padding: 100px 70px 150px 70px;
    }
    #about .about__history h2 {
        font-size: 24px;
    }
    #about .about__history p {
        font-size: 16px;
    }
    #about .about__content-container{
        padding-right: 70px;
        padding-left: 70px;
    }
    #about .about__image-content .image-container {
        border: 0px solid #0064a318;
        box-shadow: 0px 0px 0px rgb(0 101 163 / 50%);
    }
}

@media only screen and (max-width: 700px){
    #about .about__text-content p span {
        line-height: 29.26px;
        font-size: 22px;
    }
    #about .about__text-content p {
        font-size: 14px;
    }
    #about .about__content-container {
        padding-right: 50px;
        padding-left: 50px;
    }
    #about .about__text-content {
        padding: 100px 70px 100px 70px;
    }
    #about .about__image-content {
        padding-bottom: 100px;
    }    
}


@media only screen and (max-width: 578px){
    #about .about__main h1 {
        bottom: 10%;
        left: 40px;
        font-size: 18px;
    }
    #about .about__text-content p {
        font-size: 12px;
    }
    #about .about__image-content {
        padding-bottom: 20px;
    }
    #about .about__text-content p span {
        font-size: 18px;
    }
    #about .about__image-content .image-container h2 {
        font-size: 24px;
    }
    #about .about__content-container {
        padding-right: 30px;
        padding-left: 30px;
    }
    #about .about__history {
        background: #F0F9FB;
        padding-bottom: 80px;
    }
    #about .about__text-content {
        padding: 70px 50px 70px 50px;
    }
    #about .about__image-content .image-container {
        padding: 30px 0 100px 0;
    }
    #about .about__quality h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
    }
    #about .about__image-content .image-container .row .img-block {
        margin-top: 80px;
        width: 45%;
    }
}

@media only screen and (max-width: 450px){
    #about .about__text-content {
        padding: 50px 20px 70px 20px;
    }
    #about .about__text-content p {
        font-size: 10px;
    }
    #about .about__text-content p span {
        font-size: 14px;
    }
    #about .about__content-container {
        padding-right: 20px;
        padding-left: 20px;
    }
    #about .about__history h2 {
        font-size: 18px;
    }
    #about .about__history p {
        font-size: 12px;
    }
    #about .about__quality h2 {
        font-size: 18px;
    }
    #about .about__quality p {
        font-size: 12px;
    }
    #about .about__text-content {
        padding: 50px 20px 60px 20px;
    }
    #about .about__image-content .image-container h2 {
        font-size: 18px;
    }
    #about .about__main h1 {
        bottom: 10%;
        left: 14px;
        font-size: 14px;
    }
    #about .about__history h2 {
        width: 250px;
    }
    #about .about__image-content .image-container .row .img-block {
        margin-top: 80px;
        width: 40%;
    }
    #about .about__image-content .image-container h2 {
        width: 250px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 380px){
#about .about__main h1 {
        font-size: 12px;
    }
}