#services{
    padding-top: 150px;
    padding-bottom: 160px;
    background: #F0F9FB;
}
.services__content-container{
    padding-left: 100px;
    padding-right: 100px;
}
#services .row>* {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
}
#services h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 200px;
    margin-bottom: 50px;
}
#services .services__photos{
    display: flex;
    margin-bottom: 50px;
    height: 620px;
}
#services .services__photos .services__photos__item{
    
}
#services .services__photos .services__photos__item img{
    width: 100%;
    height: 618px;
}
#services .services__photos .services__photos__items{
    margin-left: 20px;
    height: 100%;
}
#services .services__photos .services__photos__items img{
width: 100%;
height: 48%;
}
#services .services__photos .services__photos__items img:nth-child(2){
    margin-top: 20px;
    height: 48%;
}
#services > h4{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #0E302C;
    margin-bottom: 100px;
}
#services .services__about{
    background: #F0F9FB;
}
#services .services__about .services__about-logo{
   position: relative;
} 
#services .services__about .services__about-logo img:nth-child(2){
    position: absolute;
    top: 30px;
    left: 20px;
}
#services .services__about .services__about-text{
    margin-left: 42px;
}
#services .services__about .services__about-text h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: #00A1E4;
    margin-bottom: 20px;
}
#services .services__about .services__about-text p{
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #111111;
}
#services .services_second{
    margin-top: 90px;
}

@media only screen and (max-width: 1100px){
    #services .services__photos{
        height: 550px;
    }
    #services .services__photos .services__photos__item img{
        height: 548px;
    }
}
@media only screen and (max-width: 1020px){
    #services .services__photos{
        height: 500px;
    }
    #services .services__photos .services__photos__item img{
        height: 498px;
    }
}
@media only screen and (max-width: 992px){
    #services .services__photos .services__photos__items{
        display: flex;
        margin-left: 0px;
        height: 100%;
        margin-top: 20px;
    }
    #services .services__photos{
        height: 100%;
    }
    #services .services__photos .services__photos__item img{
        height: 100%;
        width: 100%;
    }
    #services .services__photos .services__photos__items img:nth-child(2) {
        margin-top: 0px;
        height: 48%;
        margin-left: 20px;
        width: 100%;
    }
}
@media only screen and (max-width: 905px){
    .services__content-container{
        padding-left: 70px;
        padding-right: 70px;
    }
}
@media only screen and (max-width: 840px){
    .services__content-container{
        padding-left: 50px;
        padding-right: 50px;
    }
    #services {
        padding-top: 110px;
        padding-bottom: 110px;
    }
}
@media only screen and (max-width: 806px){
    .services__content-container{
        padding-left: 0;
        padding-right: 0;
    }
    #services h2{
        padding-left: 30px;
        padding-right: 30px;
    }
    #services .services__photos .services__photos__items{
        padding-left: 30px;
        padding-right: 30px;
    }
    #services > h4{
        padding-left: 30px;
        padding-right: 30px;
    }
    #services .services__about{
        padding-left: 30px;
        padding-right: 30px;
    }
    .row{
        margin-right: 0;
        margin-left: 0;
    }
    #services .services__photos .services__photos__items img{
        width: 48%;
    }
    #services .services__photos .services__photos__items img:nth-child(2) {
        width: 48%;
    }
}
@media only screen and (max-width: 576px){
    #services h2 {
        font-size: 25px;
    }
    #services > h4 {
        font-weight: 500;
        font-size: 16px;
    }
    #services .services__about .services__about-text p {
        font-size: 12px;   
    }
    #services .services__about .services__about-text h5 {
        font-size: 14px;
    }
    #services .services__about .services__about-logo img:nth-child(1){
        width: 80px;
        height: 80px;
     } 
   
}

@media only screen and (max-width: 420px){
    #services .services__photos .services__photos__items {
        padding-left: 14px;
        padding-right: 14px;
    }
    #services .services__photos .services__photos__items img:nth-child(2) {
        margin-left: 14px;
    }
    #services .services__photos .services__photos__items {
        margin-top: 14px;
    }
    #services > h4 {
        padding-left: 14px;
        padding-right: 14px;
        font-size: 12px;
    }
    #services .services__about .services__about-text p {
        font-size: 10px;
    }
    #services .services__about .services__about-text h5 {
        font-size: 12px;
        margin-bottom: 14px;
    }
    #services .services__about .services__about-text {
        margin-left: 30px;
    }
    #services .services__about .services__about-logo {
        margin-top: 40px;
    }
}