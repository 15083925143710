#project__page-item{
    background: #F0F9FB;
    padding-bottom: 150px;
    padding-top: 100px;
}
#project__page-item img{
    width: 100%;
}
#project__page-item .image__block > div{
    margin-top: 30px;
}
.projects-item__content-container{
    padding-left: 100px;
    padding-right: 100px;
}
#project__page-item > h2{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(to right, red -18%, #0D61BC 46%, green 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 400px;
    margin-bottom: 70px;
}
#project__page-item > p{
    margin-top: 30px;
    margin-bottom: 100px;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    font-weight: 400;
}
#project__page-item .projects__image-block p{
    margin-top: 30px;
}

#project__page-item .projects__image-block .projects__image-block-item{
    margin-bottom: 70px;
}
#project__page-item .projects__image-block .projects__image-block-item a{
    text-decoration: none;
    color: black;
}
#project__page-item .projects__image-block .projects__image-block-item a:hover{
    text-decoration: underline;
}


@media only screen and (max-width: 576px){
    .projects-item__content-container {
        padding-left: 50px;
        padding-right: 50px;
    }
    #project__page-item {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    #project__page-item > h2 {
        font-size: 24px;
        margin-bottom: 50px;
        width: 300px;
    }
    #project__page-item .image__block > div{
        margin-top: 20px;
    }
    #project__page-item .image__block > div:nth-child(even){
        padding-right: 10px;
    }
    #project__page-item .image__block > div:nth-child(odd){
        padding-left: 10px;
    }
    #project__page-item > p {
        font-size: 14px;
        margin-bottom: 60px;
    }
    #project__page-item .projects__image-block p {
        font-size: 14px;
    }
    .projects-item__content-container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 400px){
    #project__page-item > h2 {
        font-size: 24px;
        margin-bottom: 50px;
        width: 250px;
    }
}
